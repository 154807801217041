<template>
 <b-modal
  ok-only
  @ok="submit"
  id="modal-1"
  title="BootstrapVue"
  >
    <b-form>
      <b-form-group>
        <b-form-input placeholder="Fee" v-model="userEdit.donate_fee"></b-form-input>
      </b-form-group>
    </b-form>
  </b-modal>
</template>
<script>

import { BModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue'

export default {
  watch: {
    userData(newData) {
      this.userEdit = newData
    },
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      userEdit: this.userData,
    }
  },
  methods: {
    submit() {
      this.$emit('change', {
        ...this.userEdit,
      })
    },
  },
}
</script>
