<template>
  <div class="overflow-auto">
    <b-row>
      <b-col>
        <b-button @click="refreshOrders" ><b-spinner small v-if="loading" />
          <feather-icon icon="RefreshCwIcon" v-if="!loading" size="12" /> Refresh
        </b-button>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col cols="12">
        <b-overlay :show="loading">
          <b-table
            striped
            hover
            id="my-table"
            :fields="fields"
            :items="items"
            :per-page="0"
            :current-page="currentPage"
          >
            <template
              #cell(id)="data"
            >
              <col style="width: 10px">{{ data.item.id }}</col>
            </template>
            <template
              #cell(amount)="data"
            >
              {{ data.item.amount }} {{ data.item.unit_money }}
            </template>
            <template 
              #cell(donate_fee)="data"
            >
              <col style="width: 20px">{{ data.item.donate_fee }}%</col>
            </template>
            <template #cell(act)="data">
              <col :style="{ width: '300px' }">
              <b-button
                variant="success"
                @click="editUser(data.item)"
                size="sm"
                v-b-modal.modal-1
              >
                <feather-icon
                  icon="EditIcon"
                  size="12"
                /></b-button>
              </col>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col cols="12">
        <b-pagination
          v-if="total > perPage"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
          @change="changePage"
        />
      </b-col>
    </b-row>
    <edit-user :userData="userData" @change="changeUser" />
  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BOverlay, BTable, BPagination, BButton, BCol, BRow, BSpinner } from 'bootstrap-vue'
import EditUser from './components/EditUser.modal.vue'
import useJwt from '@/auth/jwt/useJwt'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'id',
        },
        {
          key: 'name',
        },
        {
          key: 'balance',
        },
        {
          key: 'donate_fee',
        },
        {
          key: 'email',
        },
        {
          key: 'phone_number',
        },
        {
          key: 'act',
          label: '',
        },
      ],
      perPage: 10,
      currentPage: 1,
      items: [],
      total: 0,
      userData: {

      },
    }
  },
  components: {
    EditUser,
    BOverlay,
    BSpinner,
    BCol,
    BRow,
    BTable,
    BPagination,
    BButton,
    FeatherIcon,
  },
  mounted() {
    this.changePage(1)
  },
  methods: {
    refreshOrders() {
      this.changePage(1)
    },
    editUser(e) {
      this.userData = e
    },
    delUser() {
    },
    changePage(e) {
      this.loading = true
      useJwt.getUsers(e).then(res => {
        this.loading = false
        this.$set(this, 'items', res.data.data)
        if (e === 1) this.$set(this, 'total', res.data.total)
        this.$set(this, 'currentPage', e)
      })
    },
    changeUser(e) {
      useJwt.updateUsers(e.id, e).then(({ data }) => {
        if (data.result === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error !',
              icon: 'BellIcon',
              variant: 'danger',
              text: data.error,
            },
          })
        }
      })
    },
  },
}
</script>
